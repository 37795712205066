import React, { useState, useRef } from "react";
import { navigate } from "gatsby";
import { FormFieldManaged, CheckBox } from "../components/FormField";
import MenuLogoPOT from "../components/MenuLogoBlackPOT";
import {
  sendEmail,
  addToSheets,
  backupSubmit,
  resetButton,
  disableButton,
} from "../utils/functions";
import { Seo } from "../components/Seo";

// Head function
export const Head = ({ location }) => (
  <Seo pathname={location.pathname} />
);

const JoinUsFormPage = () => {
  const [state, setState] = useState({
    name: "",
    time_in_sweden: "",
    city: "",
    email: "",
    social_page: "",
    receive_newsletter: false,
  });

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setState({ ...state, [e.target.name]: value });
  };

  const validate = (inputData) => {
    if (!inputData.name) return false;
    if (!inputData.email) return false;
    if (!inputData.social_page) return false;
    return true;
  };

  const submitButtonRef = useRef(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate(state)) {
      const { info, ...formData } = state;

      // Prevent multiple clicks before submission succeeds
      disableButton(submitButtonRef);

      // Peform submission
      const form = e.target;
      const actionURL = form.getAttribute("action");
      const formName = form.getAttribute("name");
      if (info) return navigate(actionURL);
      try {
        const { ok: sheetsOk } = await addToSheets(formName, formData);
        if (sheetsOk) {
          const { ok } = await sendEmail(formName, formData);
          if (ok) return navigate(actionURL);
        }
        const { ok } = await backupSubmit(formName, state);
        if (ok) return navigate(actionURL);
        throw new Error("Form submission failed :( Please, try again.");
      } catch (error) {
        resetButton(submitButtonRef);
        alert(error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 py-6 mob:px-6 sm:px-8">
      <div className="mx-auto lg:w-9/12">
        <nav>
          <MenuLogoPOT />
        </nav>
        <p className="mt-4 text-lg md:mt-6 xl:mt-8 lg:text-xl">Dear You!</p>
        <p className="mt-2 lg:text-lg">
          We are happy to see you on our website, and you are more than welcome
          to join our community! Please, fill out and submit the simple form
          below.
        </p>
      </div>
      <div className="mt-6 max-w-screen-sm">
        <form
          name="joinUsForm"
          method="post"
          action="/success/"
          onSubmit={handleSubmit}
          netlify-honeypot="info"
          data-netlify="true"
          className="p-6 bg-gray-100 rounded shadow-md"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="joinUsForm" />
          <div hidden aria-hidden="true">
            <label>
              Do not fill this out:{" "}
              <input name="info" onChange={handleChange} />
            </label>
          </div>

          <FormFieldManaged
            fieldType="text"
            fieldLabel="What is your name?"
            fieldName="name"
            handleChange={handleChange}
            fieldPlaceholder="Your name"
            isRequired={true}
          />
          <FormFieldManaged
            fieldType="text"
            fieldLabel="How long have you been in Sweden?"
            fieldName="time_in_sweden"
            handleChange={handleChange}
            fieldPlaceholder="How long in Sweden"
          />
          <FormFieldManaged
            fieldType="text"
            fieldLabel="Which city do you live in?"
            fieldName="city"
            handleChange={handleChange}
            fieldPlaceholder="City in Sweden"
          />
          <FormFieldManaged
            fieldType="email"
            fieldLabel="What is your email?"
            fieldName="email"
            fieldPlaceholder="john.doe@example.com"
            handleChange={handleChange}
            isRequired={true}
          />
          <FormFieldManaged
            fieldType="url"
            fieldLabel="Your Facebook or LinkedIn page (we use it to contact you)"
            fieldName="social_page"
            fieldPlaceholder="Your Facebook or LinkedIn page"
            handleChange={handleChange}
            isRequired={true}
          />
          <CheckBox
            checkBoxLabel="I want to receive PeformOnTop newsletter"
            checkBoxName="receive_newsletter"
            handleChange={handleChange}
            isChecked={state.receive_newsletter}
          />
          <button
            ref={submitButtonRef}
            type="submit"
            className="w-full bg-orange-500 submit-btn hover:bg-orange-700"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default JoinUsFormPage;
