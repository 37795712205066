import React from "react";
import PropTypes from "prop-types";

// File upload
export const uploadFileFormats = "application/pdf";

export const FileUpload = ({
  fieldLabel,
  fieldName,
  handleUpload,
  isValid,
  maxKb,
  isRequired = true,
}) => {
  return (
    <div className="mb-4">
      <label className="block font-bold text-gray-700 lg:text-lg">
        {fieldLabel}
        <input
          className="w-full px-3 py-2 mt-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:ring"
          type="file"
          name={fieldName}
          accept={uploadFileFormats}
          required={isRequired}
          onChange={handleUpload}
        />
      </label>
      {isValid ? null : (
        <p className="mb-4 text-sm text-red-600">
          File must be &#8804; {maxKb}Kb in size and of type .pdf
        </p>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  handleUpload: PropTypes.func.isRequired,
  maxKb: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool,
};

// Textual input externally managed: text, email, link, etc.
export const FormFieldManaged = ({
  fieldType,
  fieldLabel,
  fieldName,
  handleChange,
  fieldPlaceholder = null,
  fieldPattern = null,
  handleInvalid = null,
  isRequired = false,
}) => (
  <div className="mb-4">
    <label className="block font-bold text-gray-700 lg:text-lg">
      {fieldLabel}
      <input
        className="w-full px-3 py-2 mt-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
        type={fieldType}
        name={fieldName}
        placeholder={fieldPlaceholder}
        required={isRequired}
        pattern={fieldPattern}
        onInvalid={handleInvalid}
        onChange={handleChange}
      />
    </label>
  </div>
);

FormFieldManaged.propTypes = {
  fieldType: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  fieldPlaceholder: PropTypes.string,
  fieldPattern: PropTypes.string,
  handleInvalid: PropTypes.func,
  isRequired: PropTypes.bool,
};

// Textual input: text, email, link, etc.
export const FormField = ({
  fieldType,
  fieldLabel,
  fieldName,
  fieldPlaceholder = null,
  isRequired = false,
}) => (
  <div className="mb-4">
    <label className="block font-bold text-gray-700 lg:text-lg">
      {fieldLabel}
      <input
        className="w-full px-3 py-2 mt-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
        type={fieldType}
        name={fieldName}
        placeholder={fieldPlaceholder}
        required={isRequired}
      />
    </label>
  </div>
);

FormField.propTypes = {
  fieldType: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldPlaceholder: PropTypes.string,
  isRequired: PropTypes.bool,
};

// Checkbox managed
export const CheckBox = ({
  checkBoxLabel,
  checkBoxName,
  isChecked,
  handleChange = null,
  isRequired = false,
}) => (
  <div className="flex items-center mb-4">
    <div className="flex items-center h-5">
      <input
        className="w-4 h-4 mr-2 rounded"
        id={checkBoxName}
        type="checkbox"
        name={checkBoxName}
        checked={isChecked}
        onChange={handleChange}
        required={isRequired}
      />
    </div>
    <label
      htmlFor={checkBoxName}
      className="block font-bold text-gray-700 lg:text-lg"
    >
      {checkBoxLabel}
    </label>
  </div>
);

CheckBox.propTypes = {
  checkBoxLabel: PropTypes.string.isRequired,
  checkBoxName: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

// Radio button managed
const RadioButton = ({
  radioButtonLabel,
  radioButtonName,
  radioButtonValue,
  isChecked,
  handleChange = null,
}) => (
  <div className="mt-2">
    <label>
      <input
        className="mr-2"
        type="radio"
        name={radioButtonName}
        value={radioButtonValue}
        checked={isChecked}
        onChange={handleChange}
      />
      {radioButtonLabel}
    </label>
  </div>
);

RadioButton.propTypes = {
  radioButtonLabel: PropTypes.string.isRequired,
  radioButtonName: PropTypes.string.isRequired,
  radioButtonValue: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export const RadioButtonOptions = ({
  optionsLabel,
  radioButtonName,
  currentValue,
  options,
  handleChange = null,
}) => {
  const radioButtons = options.map((option, idx) => (
    <RadioButton
      radioButtonName={radioButtonName}
      radioButtonLabel={option}
      radioButtonValue={option}
      isChecked={currentValue === option}
      key={`${radioButtonName}-${idx}`}
      handleChange={handleChange}
    />
  ));
  return (
    <div className="mb-4">
      <p className="font-bold text-gray-700 lg:text-lg">{optionsLabel}</p>
      {radioButtons}
    </div>
  );
};

RadioButtonOptions.propTypes = {
  optionsLabel: PropTypes.string.isRequired,
  radioButtonName: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
};

// Text area managed
export const TextArea = ({
  textAreaLabel,
  textAreaName,
  textAreaPlaceholder,
  handleChange = null,
  isRequired = false,
}) => (
  <div className="mb-4">
    <label className="block font-bold text-gray-700 lg:text-lg">
      {textAreaLabel}
      <textarea
        className="w-full px-3 py-2 mt-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
        name={textAreaName}
        placeholder={textAreaPlaceholder}
        onChange={handleChange}
        required={isRequired}
      />
    </label>
  </div>
);

TextArea.propTypes = {
  textAreaLabel: PropTypes.string.isRequired,
  textAreaName: PropTypes.string.isRequired,
  textAreaPlaceholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};
