import qs from "querystring";

const emailURL = "/.netlify/functions/email";
const sheetsURL = "/.netlify/functions/sheets";
const driveURL = "/.netlify/functions/drive";

const enhancedURL = (originalURL, queryData) => {
  return `${originalURL}?${qs.encode(queryData)}`;
};

const encode = (data) => {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
};

export const addToSheets = (formName, formData) => {
  const url = enhancedURL(sheetsURL, { form: formName });
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qs.encode(formData),
  });
};

export const addToDrive = (formName, senderName, fileCategory, file) => {
  if (file) {
    const url = enhancedURL(driveURL, {
      form: formName,
      category: fileCategory,
      name: senderName,
    });
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/pdf",
      },
      body: file,
    });
  }
  return Promise.resolve(
    new Response(null, { status: 204, statusText: "No Content" })
  );
};

export const sendEmail = (formName, formData) => {
  const url = enhancedURL(emailURL, { form: formName });
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qs.encode(formData),
  });
};

export const backupSubmit = (formName, data) =>
  fetch("/", {
    method: "POST",
    body: encode({
      "form-name": formName,
      ...data,
    }),
    // Headers are set automatically by the browser
    // see: https://stackoverflow.com/questions/35192841/fetch-post-with-multipart-form-data
    // headers: { "Content-Type": "multipart/form-data; boundary=random" },
  });

export const resetButton = (buttonRef) => {
  buttonRef.current.disabled = false;
  buttonRef.current.className =
    "w-full bg-orange-500 submit-btn hover:bg-orange-700";
  buttonRef.current.innerHTML = "Send";
};

export const disableButton = (buttonRef) => {
  buttonRef.current.disabled = true;
  buttonRef.current.className = "w-full bg-gray-500 submit-btn";
  buttonRef.current.innerHTML = "Please wait...";
};
